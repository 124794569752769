


















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { RedCapital, SelectedCountry, TitleTemplatePublic } from '@/configuration'
import html2canvas from 'html2canvas';

@Component({
	metaInfo: {
		//title: '¿Como funciona?',
		//titleTemplate: TitleTemplatePublic,
	htmlAttrs: {
		// @ts-ignore
		lang: RedCapital[SelectedCountry].Lang,
	}
},
data() {
	return {
		RedCapital, SelectedCountry,
		tabs: null,
		navigationNext: `<i class="material-icons primary--text" style="font-size: 30px;">keyboard_arrow_right</i>`,
		navigationPrevious: `<i class="material-icons primary--text" style="font-size: 30px;">keyboard_arrow_left</i>`,
	}
},
})
export default class Minimoainvertir extends Vue {}
