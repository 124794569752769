<template>
    <div class="rc-simulador">
        <v-container class="rc-padding fluid">
            <v-layout row wrap>
                <v-flex xs12 style="text-align: center;margin-bottom:5rem">
                    <h1 class="rc-tituloSection">Simulador de inversión</h1>
                </v-flex>
            </v-layout>
            <v-layout row pb-5 wrap>
                <v-flex xs12>
                    <v-card class="rc-shadow mx-auto fill-height" style="min-height:550px;max-width: 1200px;">
                        <div class="simulador__content">
                            <div class="content__coin" style="display:flex; justify-content: end;">
                                <div style="display: flex;justify-content: center;width: 50%;">
                                    <img src="/images/simulador/coin_simulador.png" alt="coin simulador">
                                </div>
                            </div>
                            <div class="content__titulo">
                                <span v-html="titulo"></span>
                            </div>
                            <div class="content__body">
                                <div class="body__slider">
                                     <!-- Cash -->
                                     <div class="slider__cash">
                                        <div class="cash__title">
                                            <span class="title__txt">{{sliderTitulo1}}</span>
                                            <span class="title__cash">{{sliderCash | currency('S/')}}</span>
                                        </div>
                                        <v-slider
                                            v-model="sliderCash"
                                            :min="10000"
                                            :max="1000000"
                                        ></v-slider>
                                        <div class="cash__label">
                                            <span>S/10.000</span>
                                            <span>+S/1.000.000</span>
                                        </div>
                                    </div>
                                    <!-- Day -->
                                    <div class="slider__day">
                                         <div class="day__title">
                                            <span class="title__txt">{{sliderTitulo2}}</span>
                                            <span class="title__day">{{sliderDay}} Días</span>
                                        </div>
                                        <v-slider
                                            v-model="sliderDay"
                                            :max="120"
                                            :min="15"
                                        ></v-slider>
                                        <div class="day__label">
                                            <span class="label__start">15 Días</span>
                                            <span class="label__end">120 Días</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="body__resumen">
                                    <div class="resumen__title">
                                        <span>Resumen del financiamiento</span>
                                    </div>
                                    <ul class="resumen__detail">
                                        <li>
                                            <span class="detail__txt">Monto invertido</span>
                                            <span class="detail__cash">{{sliderCash | currency('S/') }}</span>
                                        </li>
                                        <li>
                                            <span class="detail__txt">Tasa de interés Anual</span>
                                            <span class="detail__cash">{{sliderTasaAnual}} %</span>
                                        </li>
                                        <li>
                                            <span class="detail__txtfinal">Retorno esperado</span>
                                            <span class="detail__cashfinal">{{sliderRetorno | currency('S/')}}</span>
                                        </li>
                                    </ul>
                                    <div class="resumen__paragraph">
                                        <p>
                                            *esta rentabilidad esta sujeta al impuesto a la renta dependiendo
                                            del inversionista. La tasa de interés puede variar.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	
	@Component({
		props: {
			titulo : String,
            sliderTitulo1: String,
            sliderTitulo2: String,
		},
        data() {
            return {
                sliderDay: 30,
                sliderCash: 10000,
                sliderTasaAnual: 0,
                sliderRetorno: 0,
                tasaAnual: 12,
                diasAnual: 360,
                ticksLabels: [
                    '30',
                    '60',
                    '90',
                    '120'
                    ]
            }
        },
        beforeMount() {
            // @ts-ignore
            this.calcSimulador();
        },
        methods: {
            calcSimulador(){
                this.resetTotal();
                // Calculo Tasa
                this.$data.sliderTasaAnual = ((this.$data.tasaAnual * this.$data.sliderDay) / this.$data.diasAnual).toFixed(2);
                // Retorno Esperado
                this.$data.sliderRetorno = Math.round(((this.$data.sliderCash * this.$data.sliderTasaAnual) / 100 ) + this.$data.sliderCash);
            },
            resetTotal(){
                this.$data.sliderTasaAnual = 0;
                this.$data.sliderRetorno = 0;
            }
        },
        watch: {
            sliderDay(data) {
                // @ts-ignore
                this.calcSimulador();
            },
            sliderCash(data) {
                // @ts-ignore
                this.calcSimulador();
            }
        }
	})
	export default class Factoring extends Vue {}
</script>

<style lang="scss" scoped>
    .rc-simulador{
        min-height: 800px;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        .simulador__content{
            display: flex;
            flex-flow: column;
            padding: 3rem 2rem;
            .content__coin{
                display: flex;
               
                    img{
                        position: absolute;
                        width: 200px;
                        top: -60px;
                    }
            }
            .content__titulo{
                font-size: 1.5rem;
                span{
                    color: $rc-prim-g2;
                }
                margin-bottom: 2rem;
            }
            .content__body{
                display: flex;
                flex-flow: wrap;
                .body__slider{
                    width: 50%;
                    order: 1;
                    .slider__cash{
                        max-width: 500px;
                        margin-bottom: 2rem;
                        .cash__title{
                            display: flex;
                            flex-flow: column;
                            .title__txt{
                                font-size: 1.2rem;
                            }
                            .title__cash{
                                font-size: 1.2rem;
                                font-weight: 600;
                                color: $rc-prim-n1;
                            }
                        }

                        .cash__label{
                            display: flex;
                            justify-content: space-between;
                            span{
                                font-size: 14px;
                                color: #DEE2E6;
                            }
                        }
                    }
                    .slider__day{
                        max-width: 500px;
                        .day__title{
                            display: flex;
                            flex-flow: column;
                            .title__txt{
                                font-size: 1.2rem;
                            }
                            .title__day{
                                font-size: 1.2rem;
                                font-weight: 600;
                                color: $rc-prim-n1;
                            }
                        }
                        .day__label{
                            display: flex;
                            justify-content: space-between;
                            span{
                                font-size: 14px;
                                color: #DEE2E6;
                            }
                        }
                    }
                }
                .body__resumen{
                    width: 50%;
                    order:2;
                    margin-top: 60px;
                    .resumen__title{
                        display: flex;
                        justify-content: center;
                        span{
                             font-size: 1.2rem;
                        }
                        margin-bottom: 2rem;
                    }
                    .resumen__detail{
                        list-style: none;
                        li{
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 1rem;
                            .detail__txt{
                                font-size: 1rem;
                                font-weight: 600;
                            }
                            .detail__cash{
                                font-size: 1.1rem;
                                font-weight: 600;
                            }
                            .detail__txtfinal{
                                font-size: 1.2rem;
                                font-weight: 600;
                            }
                            .detail__cashfinal{
                                display: flex;
                                align-items: center;
                                font-size: 1.2rem;
                                color: #FFF;
                                background: $rc-prim-n1;
                                border-radius: 8px;
                                padding: 0.6rem 1rem;
                                font-weight: 600;
                            }
                        }
                    }
                    .resumen__paragraph{
                        p{
                            padding-left: 1.5rem;
                        }
                    }
                }
            }
        }
    }
    // Breakpoints 
	@each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
                
			}@else if $breakpoint == "large"{
				
			}@else if $breakpoint == "medium-to-large"{
                
            }@else if $breakpoint == "small-to-medium"{
                .simulador__content{
                    .content__coin{
                        justify-content: center !important;
                    }
                    .content__titulo{
                        margin-top: 8rem !important;
                    }
                    .content__body{
                        .body__slider{
                            width: 100% !important;
                        }
                        .body__resumen{
                            width: 100% !important;
                            .resumen__detail{
                                padding-left: 0 !important;
                            }
                            .resumen__paragraph{
                                p{
                                    padding-left: 0 !important;
                                }
                            }
                        }
                    }
                }
            }@else if $breakpoint == "small"{
                .simulador__content{
                    .content__coin{
                        justify-content: center !important;
                    }
                    .content__titulo{
                        margin-top: 8rem !important;
                    }
                    .content__body{
                        .body__slider{
                            width: 100% !important;
                        }
                        .body__resumen{
                            width: 100% !important;
                            .resumen__detail{
                                padding-left: 0 !important;
                            }
                            .resumen__paragraph{
                                p{
                                    padding-left: 0 !important;
                                }
                            }
                        }
                    }
                }
            }
		}
	}
</style>
