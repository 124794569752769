<template>
	<div class="rc-interviene">
        <div class="interviene__content">
            <v-layout row pb-5 wrap>
                <v-flex lg7 md6 sm12 xs12 class="interviene__text">
                    <p class="text__titulo">
                        En este proceso interviene <span><strong>RedCapital</strong></span>,<br>
                        se encargará de:
                    </p>
                    <p class="text__parrafo">
                        <ul>
                            <li>Realizar una exhaustiva evaluación del pagador y proveedor</li>
                            <li>Gestión operativa y legal</li>
                            <li>Gestión de la cobranza</li>
                        </ul>
                    </p>
                </v-flex>
                <v-flex lg5 md6 sm12 xs12 class="interviene__img">
                    <v-img lazy src="/images/como-funcionaView/AnimCol1.gif" aspect-ratio="1.7" contain style="width:100%;height:100%"></v-img>
                </v-flex>
            </v-layout>
        </div>
    </div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	
	@Component({
	/* 	props: {
			titulo : String,
			listado : Array
        }, */
	})
	export default class Interviene extends Vue {}
</script>

<style lang="scss" scoped>
    .rc-interviene{
        display: flex;
        justify-content: center;
        .interviene__content{
            min-height: 800px;
            width: 100%;
            max-width: 880px;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            .interviene__text{
                display: flex;
                align-self: center;
                justify-content:center;
                flex-flow: column;
                
                .text__titulo{
                    font-size: 1.3rem;
                    color: $rc-prim-g2;
                    span{
                        color: $rc-prim-n1;
                    }
                    margin-bottom: 2rem;
                }
                .text__parrafo{
                    font-size: 1rem;
                    ul{
                        font-size: 1.3rem;
                        li{
                            margin-bottom: 1rem;
                        }
                    }
                }
            }
        }
    }

    

	// Breakpoints 
	@each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
				.interviene__img{
                    min-height: 600px;
                }
			}@else if $breakpoint == "large"{
                .interviene__img{
                    min-height: 600px;
                }
                .interviene__text{
                    padding-left: 20px;
                }
			}@else if $breakpoint == "medium-to-large"{
                .interviene__img{
                    min-height: 500px;
                }
                .interviene__br{
                    display: none;
                }
                .interviene__text{
                    padding-left: 20px;
                }
			}@else if $breakpoint == "small-to-medium"{
                .interviene__text{
                    order: 2;
                }
                .interviene__img{
                    order: 1;
                    min-height: 500px;
                }
                .interviene__br{
                    display: none;
                }
                .text__titulo{
                    text-align: center;
                }
                .text__parrafo{
                    text-align: left;
                }
			}@else if $breakpoint == "small"{
                .interviene__text{
                    order: 2;
                }
                .interviene__img{
                    order: 1;
                    min-height: 500px;
                }
                .interviene__br{
                    display: none;
                }
                .text__titulo{
                    text-align: center;
                }
                .text__parrafo{
                    text-align: left;
                }
            }
		}
	}
</style>
