












































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { RedCapital, SelectedCountry, TitleTemplatePublic } from '@/configuration'
import { mapState } from "vuex";
import TiposdeInversion from '@/views/howitworks/TiposdeInversion.vue'
import Minimoainvertir from '@/views/howitworks/Minimoainvertir.vue'
import Recuadro from '@/components/invertirView/Recuadro.vue';
import Interviene from '@/components/invertirView/Interviene.vue';
import Simulador from "@/components/invertirView/Simulador.vue";
import API from "@/api";
@Component({
  metaInfo: {
    title: "RedCapital  | Plataforma de Financiamiento Digital ",
    //titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      amounts: {
          financed: 0,
          pymes: 0,
          renta: 0,
          lastThirtyDays: 0,

          render: {
            financed: 0,
            pymes: 0,
            renta: 0,
            lastThirtyDays: 0
          }
        },
      RedCapital, SelectedCountry,
      tabs: null,
      navigationNext: `<i class="material-icons primary--text">arrow_forward_ios</i>`,
	  navigationPrevious: `<i class="material-icons primary--text">arrow_back_ios</i>`,
		/* Video */
		videosRc:{
			video:{
				autoplay: false,
				controls: true,
				fluid: true,
				preload: 'auto',
				sources: [
					{
						src: "/images/videos/Invertir.mp4",
						type: "video/mp4",
					}
				],
				poster: "/images/videos/invertirPoster.png",
				controlBar: {
					'liveDisplay': true,
					'pictureInPictureToggle': false,
					'fullScreenToggle': false
				}
			}
		}
	}
  },
  async mounted() {
			let amounts: any = await API.getAmounts();
			if (amounts.principal != null) {
				let dataHome = {
					amountFinanced : parseFloat(amounts.tasa_incobrable),
					amountPymes : parseInt(amounts.pymes),
					amountRenta : parseFloat(amounts.rentabilidad)
				};
				this.$store.commit("setAmountDataHome", dataHome);
				this.$data.amounts.financed = parseFloat(amounts.tasa_incobrable);
				this.$data.amounts.pymes = parseInt(amounts.pymes);
				this.$data.amounts.renta = parseFloat(amounts.rentabilidad);
			} else {
				this.$data.amounts.financed = this.$store.state.amountFinanced;
				this.$data.amounts.pymes = this.$store.state.amountPymes;
				this.$data.amounts.renta = this.$store.state.amountRenta;
			}
			this.$data.amounts.lastThirtyDays = parseInt(amounts.secundario);
			
			let steps = 20;
			let timePerStep = 1;

			for (
				let i = this.$data.amounts.financed / steps, j = this.$data.amounts.pymes / steps, k = this.$data.amounts.renta / steps;
				i < this.$data.amounts.financed && j < this.$data.amounts.pymes && k < this.$data.amounts.renta;
				i += this.$data.amounts.financed / steps, j += this.$data.amounts.pymes / steps, k += this.$data.amounts.renta / steps 
			) {
				await new Promise((res, rej) => {
					setTimeout(() => {
						// @ts-ignore
						this.$data.amounts.render.financed = parseInt(i);
						// @ts-ignore
						this.$data.amounts.render.pymes = parseInt(j);
						// @ts-ignore
						this.$data.amounts.render.renta = parseInt(k);
						// @ts-ignore
						res();
					}, timePerStep);
				});
			}
			this.$data.amounts.render.financed = this.$data.amounts.financed;
			this.$data.amounts.render.pymes = this.$data.amounts.pymes;
			this.$data.amounts.render.renta = this.$data.amounts.renta;
		},
  components: {
	  TiposdeInversion, 
	  Minimoainvertir,
	  Recuadro,
	  Interviene,
	  Simulador
  }
})
export default class HowItWorks extends Vue {}
