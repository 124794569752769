<template>
    <div class="rc-recuadro">
        <div class="recuadro__card">
            <div class="card__decoration">
                <div class="decoration left"></div>
                <div class="decoration right"></div>
            </div>
            <div class="card__content">
                <p>
                    A través del <span><strong>Factoring</strong></span> el inversionista puede comprar una factura e invertir su dinero a un plazo que va
                    entre <strong>30 y 120 días.</strong> Transcurrido este tiempo, y luego que el pagador haya cumplido con cancelar la
                    factura, el inversionsita recibirá el dinero invertido, sumado a la rentabilidad propuesta inicialmente.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	
	@Component({
		/* props: {
			titulo : String,
			listado : Array
        }, */
	})
	export default class Recuadro extends Vue {}
</script>

<style lang="scss" scoped>
    .rc-recuadro{
        display: flex;
        justify-content: center;
        .recuadro__card{
            background: #FBFBFB;
            border-radius: 20px;
            max-width: 880px;
            .card__content{
                padding: 2rem;
                p{
                    line-height: 28px;
                    padding: 0;
                    margin: 0;
                    font-size: 1rem;
                    color: $rc-prim-g2;
                    span{
                        color: $rc-prim-n1;
                    }
                }
            }

            .card__decoration{
                .decoration{
                    height: 30px;
                    width: 35px;
                    background: $rc-prim-sunny;
                    &.left{
                        border-radius: 51% 49% 34% 66% / 32% 37% 63% 68%;
                        position: relative;
                        top: -10px;
                        left: -10px;
                    }
                    &.right{
                        border-radius: 51% 49% 70% 30% / 32% 27% 73% 68% ;
                        position: relative;
                        top: -10px;
                        right: -10px;
                    }
                }
            }
        }
    }
    // Breakpoints 
	@each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
                
			}@else if $breakpoint == "large"{
				
			}@else if $breakpoint == "medium-to-large"{
                
            }@else if $breakpoint == "small-to-medium"{
                
            }@else if $breakpoint == "small"{
               
            }
		}
	}
</style>
