








































import { Component, Prop, Vue } from 'vue-property-decorator'
// @ts-ignore
import { Tabs, TabItem } from 'vue-material-tabs';
import { RedCapital, SelectedCountry, TitleTemplatePublic } from '@/configuration'
import html2canvas from 'html2canvas';

@Component({
  metaInfo: {
    //title: '¿Como funciona?',
    //titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    }
  },
  data() {
    return {
      RedCapital, SelectedCountry,
      tabs: null,
      navigationNext: `<i class="material-icons primary--text" style="font-size: 30px;">keyboard_arrow_right</i>`,
      navigationPrevious: `<i class="material-icons primary--text" style="font-size: 30px;">keyboard_arrow_left</i>`,
    }
  },
  mounted(){
    // Añadiendo Estilo de Card en Tipos de Inversiones y Riesgos
			// @ts-ignore
			document.getElementsByClassName('tabs__content')[0].className += ' v-card v-sheet theme--light';
  },
  components: {
	  Tabs, 
	  TabItem,
  }
})
export default class TiposdeInversion extends Vue {}
