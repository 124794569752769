import { render, staticRenderFns } from "./Recuadro.vue?vue&type=template&id=72d5cfdc&scoped=true&"
import script from "./Recuadro.vue?vue&type=script&lang=js&"
export * from "./Recuadro.vue?vue&type=script&lang=js&"
import style0 from "./Recuadro.vue?vue&type=style&index=0&id=72d5cfdc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72d5cfdc",
  null
  
)

export default component.exports